<template>
<!--  <v-row v-if="$route.path === '/dashboard'" class="mx-2 px-2 app-bar-borders" >-->
<!--    <v-col cols="12">-->
<!--    <v-alert-->
<!--        height="40"-->
<!--        dense-->
<!--        type="error"-->
<!--        color="#D90808"-->
<!--		class="mb-0"-->
<!--    >-->
<!--      <v-row>-->
<!--        <v-col class="text-left" align-self="center">-->
<!--          Patient John Doe is having SI thoughts!-->
<!--        </v-col>-->
<!--        <v-col class="shrink">-->
<!--          <v-btn :height="33" color="white" style="background-color: white; color: black; margin-top:-4.5px; margin-right:-12px;">{{$t('resolve')}}</v-btn>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-alert>-->
<!--    </v-col>-->
<!--  </v-row>-->
  <v-row v-if="$route.path === '/sareports'" class="mx-2 px-2 app-bar-borders" >
	<v-col class="d-flex justify-start" xs="8" sm="7" md="7" lg="7" xl="6">
		<v-row>
			<v-col cols="4" sm="4" xl="3" class="mx-0 px-1">
				<v-text-field
					class="width-text-field1"
					prepend-inner-icon="mdi-magnify"
					hide-details
					dense
					v-model="search"
					:label="$t('search')"
					outlined
				></v-text-field>
			</v-col>
			<v-col cols="4" sm="4" xl="4" class="ma-0 px-1">
				<v-select
					class="width-text-field2"
					hide-details
					dense
          :menu-props="{ bottom: true, offsetY: true }"
					v-model="selectedMedicalInstitution"
					:items="medicalInstitutions"
					item-value="id"
					item-text="institutionName"
					:label="$t('medicalInstitution')"
					outlined
				>
				</v-select>
			</v-col>
		</v-row>
	</v-col>
  </v-row>
  <v-row v-else-if="$route.path === '/organizations'" class="px-3 py-4 app-bar-borders" >
	<v-col class="" xs="8" sm="7" md="7" lg="7" xl="6">
		<!-- EMPTY - NO NEED FOR THIS -->
	</v-col>
  </v-row>
  <v-row dense v-else-if="$route.path === '/offices'" class="px-3 py-1 align-center d-flex app-bar-borders" >
	<v-col cols="2">
    <v-text-field
        class="width-text-field1"
        prepend-inner-icon="mdi-magnify"
        hide-details
        dense
        v-model="search"
        :label="$t('search')"
        outlined
    ></v-text-field>
	</v-col>
	<v-col class="text-right" cols="10">
      <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
      <img class="me-3" height="15" :src="addWhite"/>
      <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
    </v-btn>
	</v-col>
  </v-row>
<!--  <v-row dense v-else-if="$route.path === '/monitoring'" class="px-3 py-2 align-center d-flex" style="border-top: #e1dce8 solid 1px; border-bottom: #e1dce8 solid 1px; color: lightgray">-->
<!--    <v-col cols="2">-->
<!--      <v-text-field-->
<!--          class="width-text-field1"-->
<!--          prepend-inner-icon="mdi-magnify"-->
<!--          hide-details-->
<!--          dense-->
<!--          v-model="search"-->
<!--          :label="$t('search')"-->
<!--          outlined-->
<!--      ></v-text-field>-->
<!--    </v-col>-->
<!--    <v-col class="text-right" cols="10">-->
<!--      <v-btn  @click="syncronizeDrawers" color="primary" variant="tonal" prepend-icon="mdi-plus">-->
<!--        <img class="me-3" height="15" :src="addWhite"/>-->
<!--        <span style="text-transform: capitalize;">{{ pathname }}</span>-->
<!--      </v-btn>-->
<!--    </v-col>-->
<!--  </v-row>-->
  <v-row dense v-else-if="$route.path === '/admins'" class="px-3 py-1 align-center d-flex app-bar-borders" >
	<v-col cols="2" >
		<v-text-field
			class="py-0"
			prepend-inner-icon="mdi-magnify"
			hide-details
			dense
			v-model="search"
			:label="$t('search')"
			outlined
		></v-text-field>
	</v-col>
	<v-col cols="2" >
		<v-select
			class="width-text-field2"
			hide-details
			dense
      :menu-props="{ bottom: true, offsetY: true }"
			v-model="selectedUserStatusVerifier"
			:items="userStatusVerifiers"
			item-value="lowValue"
			item-text="shortDescription"
			:label="$t('status')"
			outlined
		>
		</v-select>
	</v-col>
	<v-col cols="2" >
		<v-select
			class="width-text-field2"
			hide-details
			dense
      :menu-props="{ bottom: true, offsetY: true }"
			v-model="selectedRole"
			:items="filteredRoles"
			item-value="id"
			item-text="roleNameForDisplay"
			:label="$t('role')"
			outlined
		>
		</v-select>
	</v-col>
	<v-col cols="6" class="text-right">
	<v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
		<img class="me-3" height="15" :src="addWhite"/>
		<span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
	</v-btn>
	</v-col>
  </v-row>

  <v-row dense v-else-if="$route.path === '/medicalstaff'" class="px-3 py-1 align-center d-flex app-bar-borders" >
			<v-col cols="2" >
				<v-text-field
					class="py-0"
					prepend-inner-icon="mdi-magnify"
					hide-details
					dense
					v-model="search"
					:label="$t('search')"
					outlined
				></v-text-field>
			</v-col>
			<v-col cols="2" >
				<v-select
					class="width-text-field2"
					hide-details
					dense
          :menu-props="{ bottom: true, offsetY: true }"
					v-model="selectedRole"
					:items="filteredRoles2"
					item-value="id"
					item-text="roleNameForDisplay"
					:label="$t('role')"
					outlined
				>
				</v-select>
			</v-col>
			<v-col cols="2" class="ma-0 px-1">
				<v-select
					class="py-1"
					hide-details
					dense
          :menu-props="{ bottom: true, offsetY: true }"
					v-model="surgery"
					v-if="$route.path === '/medicalstaff'"
					item-text="longDescription"
					item-value="lowValue"
					:items="getSpecializations"
					:label="$t('specialization')"
					outlined
				></v-select>
			</v-col>
			<v-col cols="6" class="text-right">
			<v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
				<img class="me-3" height="15" :src="addWhite"/>
				<span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
			</v-btn>
			</v-col>
  </v-row>
  <v-row v-else-if="$route.path === '/patients'" class="mx-2 px-2 app-bar-borders">
	<v-col class="d-flex justify-start" xs="8" sm="7" md="7" lg="7" xl="6">
		<v-row>
			<v-col cols="4" sm="4" xl="3" class="mx-0 px-1">
				<v-text-field
					class="width-text-field1"
					prepend-inner-icon="mdi-magnify"
					hide-details
					dense
					v-model="search"
					:label="$t('search')"
					outlined
				></v-text-field>
			</v-col>
			<v-col cols="3" sm="3" xl="3" class="ma-0 px-1">
				<v-select
					class="width-text-field2"
					hide-details
					dense
          :menu-props="{ bottom: true, offsetY: true }"
					v-model="selectedUserStatusVerifier"
					:items="userStatusVerifiers"
					item-value="lowValue"
					item-text="shortDescription"
					:label="$t('status')"
					outlined
				>
				</v-select>
			</v-col>
		</v-row>
	</v-col>
	<v-col class="1">
		<!-- <v-row>
			<v-col class="text-right">
			<v-btn  @click="syncronizeDrawers" color="primary" variant="tonal" prepend-icon="mdi-plus">
				<img class="me-3" height="15" :src="addWhite"/>
				<span style="text-transform: capitalize;">{{ pathname }}</span>
			</v-btn>
			</v-col>
		</v-row> -->
	</v-col>
  </v-row>
  <v-row dense v-else-if="$route.path === '/billings'" class="px-3 py-0 pt-1 align-center d-flex app-bar-borders">
	<v-col cols="4" >
		<v-text-field
			class="py-0"
			prepend-inner-icon="mdi-magnify"
			hide-details
			dense
			v-model="search"
			:label="$t('search')"
			outlined
		></v-text-field>
	</v-col>
	<v-col cols="3" >
	</v-col>
	<v-col cols="5" class="text-right">
		<div v-if="$route.path === '/billings' && isEmail" class="ml-auto d-flex align-center mr-2">
			<v-menu
				v-model="menu1"
				:close-on-content-click="false"
				:nudge-right="-90"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="selectedDateForBilling"
						label="Filter date"
						readonly
						v-bind="attrs"
						single-line
						hide-details
						prepend-inner-icon="mdi-calendar"
						dense
						v-on="on"
						class="mr-5"
						outlined
					></v-text-field>
				</template>
				<v-date-picker v-model="selectedDateForBilling" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
			</v-menu>
			<!-- we hide the billing button for now, because the billing codes are generated automatically -->
			<v-btn v-show="false" @click="billingDataForOrganizationalAdmin" class="primary">
				<img class="my-1" height="17px" width="20px" :src="invoice"/>
			</v-btn>
			<v-btn @click="sendBillingReport" color="chips" variant="tonal" class="ml-2" >
        <v-icon size="15" color="white">mdi-arrange-send-backward</v-icon>
			</v-btn>
      <v-btn @click="generateCSV" color="chips" variant="tonal" class="ml-2"  prepend-icon="mdi-plus">
        <span class="mdi mdi-cloud-download mdi-18px mt-1 text-capitalize text-color-white"></span>
      </v-btn>
		</div>
    <div v-if="$route.path === '/billings' && !isEmail" class="ml-auto d-flex align-center justify-end">
      <v-btn @click="sendBillingReport" color="chips" variant="tonal" class="ml-2"  prepend-icon="mdi-plus">
        <v-icon size="15" color="white">mdi-arrange-send-backward</v-icon>
      </v-btn>
      <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus" class="ml-5">
        <img class="me-3" height="15" :src="addWhite"/>
        <span class="text-capitalize text-color-white"> Create link </span>
      </v-btn>
    </div>
	</v-col>
	<v-col class="1">
		<!-- <v-row>
			<v-col class="text-right">
			<v-btn  @click="syncronizeDrawers" color="primary" variant="tonal" prepend-icon="mdi-plus">
				<img class="me-3" height="15" :src="addWhite"/>
				<span style="text-transform: capitalize;">{{ pathname }}</span>
			</v-btn>
			</v-col>
		</v-row> -->
	</v-col>
  </v-row>
  <v-row dense v-else-if="$route.path === '/dashboardAdmin'" class="px-3 py-0 pt-1 align-center d-flex app-bar-borders">
    <v-col cols="9" >
    </v-col>
    <v-col cols="3" class="text-right">
      <div v-if="$route.path === '/dashboardAdmin'" class="ml-auto d-flex align-center">
        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="-90"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="selectedDateForBilling"
                label="Filter date"
                readonly
                v-bind="attrs"
                single-line
                hide-details
                prepend-inner-icon="mdi-calendar"
                dense
                v-on="on"
                class="mr-5"
                outlined
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedDateForBilling" color="primary" type="month" no-title scrollable @change="menu1 = false"></v-date-picker>
        </v-menu>
      </div>
    </v-col>
    <v-col class="1">
      <!-- <v-row>
        <v-col class="text-right">
        <v-btn  @click="syncronizeDrawers" color="primary" variant="tonal" prepend-icon="mdi-plus">
          <img class="me-3" height="15" :src="addWhite"/>
          <span style="text-transform: capitalize;">{{ pathname }}</span>
        </v-btn>
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
  <v-row dense v-else-if="$route.path === '/billingsettings' || $route.path === '/UserBillingSettings' || $route.path === '/medicalNotesCategory'" class="px-3 py-0 pt-1 align-center d-flex app-bar-borders">
   <v-col cols="2">
     <v-text-field
         class="width-text-field1"
         prepend-inner-icon="mdi-magnify"
         hide-details
         dense
         v-model="search"
         :label="$t('search')"
         outlined
     ></v-text-field>
   </v-col>

    <v-col cols="2">
        <v-select
            class="width-text-field2 mt-1 ml-2"
            hide-details
            dense
            v-model="selectedTemplateStatus"
            :items="medicalNoteTemplateVerifiers"
            item-value="lowValue"
            v-if="$route.path === '/medicalNotesCategory'"
            item-text="shortDescription"
            :label="$t('status')"
            outlined
            :menu-props="{ offsetY: true }"
        >
        </v-select>
      </v-col>
    <v-col cols="8" class="text-right">
      <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">
        <img class="me-3" height="15" :src="addWhite"/>
        <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>
      </v-btn>
    </v-col>
  </v-row>
  <v-row dense v-else class="mx-n4 px-3 py-2 align-center d-flex app-bar-borders">
			<v-col cols="4">
				<v-text-field
					class="width-text-field1 ml-3"
					prepend-inner-icon="mdi-magnify"
					hide-details
					dense
					v-model="search"
					:label="$t('search')"
					outlined
				></v-text-field>
      </v-col>
  </v-row>
</template>

<script>
import {
	addWhite,
	billWhite,
} from '@/assets';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
	name: 'DashboardCoreDrawer',
	components: {},
	props: {
		expandOnHover: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		addWhite: addWhite,
		items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
		search: null,
    selectedTemplateStatus: 3,
		// role: 0,
		surgery: -1,
    isEmail: true,
		selectedMedicalInstitution: -1,
		selectedUserStatusVerifier: null,
		selectedRole: null,
		menu1: false,
		invoice: billWhite,
		selectedDateForBilling: new Date().toISOString().substr(0, 7),
	}),
	async mounted () {
    await this.getMedicalInstitutions(); // get the list once, after that we use it from the shared state
		await this.getAllVerifiers(); // get the list once, after that we use it from the shared state
		await this.getAllRoles(); // get the list once, after that we use it from the shared state
    // await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', -99);

    // if (this.$route.path === '/medicalNotesCategory') {
    //   await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', 1);
    // }
    // if (this.$route.path === '/admins') {
    //   await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', -99);
    // }
	},
	computed: {
		...mapState({
			allVerifiers: (state) => state.verifiers.allverifires,
			allRoles: (state) => state.roles.roles,
			userData: (state) => state.authentication.userData,
		}),
		...mapGetters({
			roles: 'roles/getRoles',
			getSpecializations: 'verifiers/getSpecializations',
			medicalInstitutions: 'medicalInstitutions/getMedicalInstitutionsSorted',
			getMedicalTeam: 'filterbar/getMedicalTeam',
			roleName: 'authentication/getRole',
		}),
    medicalNoteTemplateVerifiers () {
      let userStatusVerifiersList = this.allVerifiers != null ? this.allVerifiers.filter(v => v.rvDomain === 'MEDICAL_NOTE_CATEGORY_STATUS') : null;
      if (userStatusVerifiersList != null) {
        userStatusVerifiersList.unshift({ lowValue: 3, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      } else {
        userStatusVerifiersList = [];
        userStatusVerifiersList.unshift({ lowValue: 3, shortDescription: 'All', shortDescriptionESP: 'Todo' });
      }
      return userStatusVerifiersList;
    },
		pathname () {
			return this.$route.name;
		},
		drawer: {
			get () {
				return this.$store.state.drawer;
			},
			set (val) {
				this.$store.commit('SET_DETAILS_DRAWER', val);
			},
		},
		detailsDrawer () {
			return this.$store.state.detailsDrawer;
		},
		userStatusVerifiers () {
			let userStatusVerifiersList = this.allVerifiers != null ? this.allVerifiers.filter(v => v.rvDomain === 'USER_STATUS') : null;
			if (userStatusVerifiersList != null) {
				userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
			} else {
				userStatusVerifiersList = [];
				userStatusVerifiersList.unshift({ lowValue: -99, shortDescription: 'All', shortDescriptionESP: 'Todo' });
			}
			return userStatusVerifiersList;
		},
		filteredRoles () {
			// const fileteredRolesList = this.allRoles != null ? this.allRoles.filter(r => r.roleName === 'HOSPITAL_ADMIN' || r.roleName === 'ORGANIZATION_ADMIN' || r.roleName === 'CARE_GIVER') : [];
			const fileteredRolesList = this.allRoles != null ? this.allRoles.filter(r => r.roleName === 'HOSPITAL_ADMIN' || r.roleName === 'ORGANIZATION_ADMIN' || r.roleName === 'PARTNER') : [];
			fileteredRolesList.unshift({ id: -99, roleNameForDisplay: 'All', roleNameForDisplayESP: 'Toda' });
			return fileteredRolesList;
		},
		filteredRoles2 () {
			const fileteredRolesList = this.allRoles != null ? this.allRoles.filter(r => r.roleName === 'DOCTOR' || r.roleName === 'NURSE' || r.roleName === 'CARE_GIVER') : [];
			fileteredRolesList.unshift({ id: -99, roleNameForDisplay: 'All', roleNameForDisplayESP: 'Toda' });
			return fileteredRolesList;
		},
	},
	methods: {
    async sendBillingReport () {
      const val = this.isEmail ? 'email' : 'billing';
      await this.$store.dispatch('filterbar/billingReportState', val);
      // Toggle the flag
      this.isEmail = !this.isEmail;
    },
		...mapMutations({
			setDrawer: 'SET_DETAILS_DRAWER',
		}),
		async getMedicalInstitutions () {
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					// this.medicalInstitutions = this.medicalInstitutionsFromRepo;
				});
		},
		async getAllVerifiers () {
			// we call this API endpoint only if the store values are null
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
					// this.loading = false;
				});
			}
		},
		async getAllRoles () {
			// we call this API endpoint only if the store values are null
			if (this.allRoles == null) {
				await this.$store.dispatch('roles/getRoles').then(() => {
					// this.loading = false;
				});
			}
		},
		syncronizeDrawers () {
      var parameters = {};
			switch (this.pathname.toLowerCase()) {
        case 'medical staff':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'patients':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'medical team':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'assessments':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
        case 'monitoring':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
			case 'verifier':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'organization':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'office':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
			case 'admins':
				parameters = {
					detailsDrawer: true,
					page: this.pathname.toLowerCase(),
				};
				this.$store.commit('SET_DETAILS_DRAWER', parameters);
				break;
        case 'billing settings':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;

        case 'user billing settings':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'medical note category':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
        case 'billings':
          parameters = {
            detailsDrawer: true,
            page: this.pathname.toLowerCase(),
          };
          this.$store.commit('SET_DETAILS_DRAWER', parameters);
          break;
      }
		},
		async generateCSV () {
			// await this.$store.dispatch('filterbar/generateCSV');
			const data = { organizationId: this.userData.organizationId };
			await this.$store.dispatch('filterbar/generateCSV', data);
		},
		async billingDataForOrganizationalAdmin () {
			const data = { organizationId: this.userData.organizationId };
			await this.$store.dispatch('filterbar/billingDataForOrganizationalAdmin', data);
		},
	},
	watch: {
    '$route' (to, from) {
      if (to.path !== '/billings' || to.path !== '/dashboardAdmin') {
        // Set selectedDateForReports to today's date
        this.selectedDateForBilling = new Date().toISOString().substr(0, 7);
        this.isEmail = true;
      }
    },
		async surgery (val) {
			await this.$store.dispatch('filterbar/AppBarSpacialization', val);
		},
		async role (val) {
			await this.$store.dispatch('filterbar/AppBarRole', val);
		},
		async doctor (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalTeamDoctor', val);
		},
		async search (val) {
			await this.$store.dispatch('filterbar/AppBarSearch', val);
		},
		async selectedMedicalInstitution (val) {
			await this.$store.dispatch('filterbar/AppBarMedicalInstitution', val);
		},
		async selectedUserStatusVerifier (val) {
			await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', val);
		},
    async selectedTemplateStatus (val) {
      await this.$store.dispatch('filterbar/AppBarUserStatusVerifier', val);
    },
		async selectedRole (val) {
			await this.$store.dispatch('filterbar/AppBarRole', val);
		},
		async selectedDateForBilling (val) {
			await this.$store.dispatch('filterbar/AppBarDate', val);
		},
	},
};
</script>
